<template>
  <div
    ref="dropzone"
    class="dropzone"
    :class="{ 'dropzone--hover': isActiveDragBlock }"
  >
    <div class="dropzone-preview" v-if="uploadedFiles && uploadedFiles.length">
      <div
        v-if="docs"
        class="dropzone-preview__container"
        @click.prevent="triggerFileFieldClick"
      >
        <div
          v-for="(doc, id) in docs"
          :key="id + doc.name"
          class="dropzone-preview__doc"
        >
          <p class="dropzone-preview__name">{{ doc.name }}</p>
          <button
            type="button"
            class="remove-doc"
            @click.prevent="removeDocPreview(id)"
          >
            <AppIcon componentName="CloseIcon" />
          </button>
        </div>
      </div>
      <figure class="dropzone-preview__img" v-if="previewPhoto">
        <img :src="previewPhoto" alt="" class="img-fit-cover" />

        <button
          type="button"
          class="remove-preview"
          @click.prevent="removePreview"
        >
          <AppIcon componentName="CloseIcon" />
        </button>
      </figure>

      <p v-if="previewName" class="dropzone-preview__name">
        {{ previewName }}
      </p>
    </div>

    <div v-else class="dropzone-inner" @click.prevent="triggerFileFieldClick">
      <AppIcon componentName="UploadIcon" class="dropzone-inner__icon" />
      <h3 v-if="title" class="dropzone-inner__title">{{ title }}</h3>
      <p v-if="text" class="dropzone-inner__text">{{ text }}</p>
    </div>

    <div class="dropzone-mobile" @click.prevent="triggerFileFieldClick">
      <AppButton iconName="UploadIcon" className="white" :title="title" />
      <p v-if="text" class="dropzone-inner__text text-center">{{ text }}</p>
    </div>

    <input
      ref="fileField"
      type="file"
      class="file-field"
      :multiple="!!docTypes"
      @change="handleDrop"
    />
  </div>
</template>

<script>
import { simpleFileValidation } from "@/helpers/extansions-validations";
import AppToast from "@/helpers/toast-methods";

export default {
  name: "AppDropzone",

  props: {
    title: {
      type: String,
    },

    text: {
      type: String,
    },

    docTypes: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      docsWrap: null,
      isActiveDragBlock: false,
      uploadedFiles: null,
      previewPhoto: null,
      previewName: "",
      docs: null,
    };
  },

  methods: {
    dropNativeListeners(hook) {
      this.preventDefaults(hook);
      this.highlightDropArea(hook);
      this.dropOnDropArea(hook);
    },

    triggerFileFieldClick(e) {
      if (e.target.closest(".remove-doc")) return;
      if (this.previewPhoto) {
        this.removePreview();
      }
      this.$refs.fileField.click();
    },

    preventDefaults(hook) {
      const baseEvents = ["dragenter", "dragover", "dragleave", "drop"];

      const preventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

      baseEvents.forEach((eventName) => {
        if (hook === "mounted") {
          this.docsWrap.addEventListener(eventName, preventDefaults, false);
          return;
        }

        this.docsWrap.removeEventListener(eventName, preventDefaults, false);
      });
    },

    highlightDropArea(hook) {
      const highlightEvents = ["dragenter", "dragover"];
      const unhighlightEvents = ["dragleave", "drop"];

      const highlightHandler = () => {
        this.$nextTick(() => {
          this.isActiveDragBlock = true;
        });
      };

      const unhighlightHandler = () => {
        this.isActiveDragBlock = false;
      };

      if (hook === "mounted") {
        highlightEvents.forEach((eventName) => {
          this.docsWrap.addEventListener(eventName, highlightHandler, false);
        });

        unhighlightEvents.forEach((eventName) => {
          this.docsWrap.addEventListener(eventName, unhighlightHandler, false);
        });
        return;
      }

      highlightEvents.forEach((eventName) => {
        this.docsWrap.removeEventListener(eventName, highlightHandler, false);
      });

      unhighlightEvents.forEach((eventName) => {
        this.docsWrap.removeEventListener(eventName, unhighlightHandler, false);
      });
    },

    dropOnDropArea(hook) {
      if (hook === "mounted") {
        this.docsWrap.addEventListener("drop", this.handleDrop, false);
        return;
      }

      this.docsWrap.removeEventListener("drop", this.handleDrop, false);
    },

    async handleDrop(e) {
      const uploadedFiles = Array.from(
        e?.dataTransfer?.files || e?.target?.files
      ).filter((file) => {
        return simpleFileValidation(file, this.docTypes);
      });

      if (!uploadedFiles.length) return;

      if (this.uploadedFiles?.length) this.uploadedFiles.push(...uploadedFiles);
      else this.uploadedFiles = uploadedFiles;

      if (this.uploadedFiles.length > 4) {
        this.uploadedFiles = this.uploadedFiles.slice(0, 4);
        AppToast.toastError("The attachments mustn't have more than 4 items.");
      }

      this.docTypes
        ? (this.docs = this.uploadedFiles)
        : this.getPreviewPhoto(this.uploadedFiles[0]);

      this.$emit("uploadFiles", this.uploadedFiles);
    },

    getPreviewPhoto(file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.previewPhoto = reader.result;
        this.previewName = file.name;
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        this.previewPhoto = null;
        this.previewName = "";
        this.$refs.fileField.value = "";
      }
    },

    removeDocPreview(id) {
      this.uploadedFiles = null;
      this.previewPhoto = null;
      this.previewName = "";
      if (this.docs?.length) {
        this.docs.splice(id, 1);
        this.$emit("removeFile", id);
      }
      this.$refs.fileField.value = "";
    },

    removePreview() {
      this.uploadedFiles = null;
      this.previewPhoto = null;
      this.previewName = "";
      this.$emit("removeFile");
      this.$refs.fileField.value = "";
    },
  },

  mounted() {
    this.docsWrap = this.$refs.dropzone;

    this.dropNativeListeners("mounted");
  },

  beforeDestroy() {
    this.dropNativeListeners("beforeDestroy");
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  width: 100%;
  border: dashed 2px #8c8f96;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  height: 220px;
  transition: all 0.3s ease;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    &:hover {
      border-color: $primary;
      background-color: lighten($primary, 35%);

      .dropzone-inner {
        &__icon {
          fill: $primary;
        }
      }
    }
  }
}

.dropzone-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  text-align: center;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    display: none;
  }

  &__icon {
    width: 44px;
    height: 40px;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 12px;
    color: $light-gray;
  }
}

.dropzone--hover {
  @media (min-width: map_get($grid-breakpoints, lg)) {
    border-color: $primary;

    .dropzone-inner {
      &__icon {
        fill: $primary;
      }
    }
  }
}

.dropzone-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    padding: 5px;
  }

  &__img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: inline-flex;
    position: relative;
    transition: all 0.3s ease;
    margin-bottom: 10px;

    &:hover {
      background-color: #efefef;
    }

    img {
      overflow: hidden;
      border-radius: 50%;
    }

    .remove-preview {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      background-color: $red;
      transform: translate(50%, -50%);
      padding: 0;

      svg {
        width: 12px;
        height: 12px;
        fill: #fff;
      }

      &:hover {
        background-color: darken($red, 15%);
      }
    }
  }

  &__container {
    align-self: self-start;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding-right: 10px;
  }

  &__doc {
    display: flex;
    align-items: center;
    border-bottom: solid 1px rgba(34, 60, 80, 0.1);
    padding: 3px 5px;

    &:not(:last-of-type) {
      margin-bottom: 2px;
    }

    &:last-of-type {
      margin-bottom: 5px;
    }
  }

  .remove-doc {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background-color: $red;
    margin-left: auto;
    padding: 0;

    svg {
      width: 12px;
      height: 12px;
      fill: #fff;
    }

    &:hover {
      background-color: darken($red, 15%);
    }
  }

  &__name {
    font-size: 12px;
    color: $light-gray;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.file-field {
  display: none;
}

.dropzone-mobile {
  @media (min-width: map_get($grid-breakpoints, lg)) {
    display: none;
  }

  .dropzone-inner__text {
    font-size: 10px;
  }

  ::v-deep .btn {
    font-size: 13px;
    width: 100%;
    border: dashed 1px $primary;
  }
}
</style>
